<template>
    <div class="create-profil-vigik">
        <div class="return_page">
            <VigikBreadCrumb />
        </div>

        <div class="block-list">
            <div class="top-block">
                <h1 class="title">
                    {{ $t("vigik.config.createAccessType") }}
                </h1>
                <div>* {{ $t("vigik.form.obligatoryFields") }}</div>
            </div>
            <div class="middle-block">
                <form class="access-form" @submit="createAccessType">
                    <div class="input-field">
                        <p>{{ $t("vigik.form.name") }} *</p>
                        <div class="input-name">
                            <div class="value-number">
                                <p>{{ name.length }} / 42</p>
                            </div>
                            <basic-input
                                class="access-type-name"
                                v-model="name"
                                name="name"
                                :maxlength="42"
                                :rules="isNotEmpty"
                            />
                        </div>
                    </div>
                    <div class="input-field">
                        <p>{{ $t("vigik.form.description") }}</p>
                        <div class="description-wrapper">
                            <div class="value-number">
                                <p>{{ description.length }} / 160</p>
                            </div>
                            <basic-input
                                class="access-type-description"
                                v-model="description"
                                name="description"
                                :maxlength="160"
                                :textarea="true"
                                :resizeTextarea="false"
                            />
                        </div>
                    </div>
                    <div class="input-field">
                        <p>{{ $t("vigik.form.copy") }}</p>
                        <basic-select
                            class="select-access-type"
                            name="copyAccessType"
                            v-model="copy"
                            :limit="copyLimit"
                            :options="accessTypeList"
                            @update:modelValue="updateCopy"
                            @more="getMore"
                            @change="getCopy"
                        />
                    </div>
                    <div class="input-field">
                        <p>{{ $t("vigik.form.openingPriority") }} *</p>
                        <basic-select
                            class="select-access-type"
                            name="openingPriority"
                            v-model="openingPriority"
                            :rules="isSelected"
                            :required="true"
                            :options="openingPriorityOptions"
                        />
                    </div>
                </form>
                <div
                    v-if="copy !== '0' && copy && !loading"
                    class="list-display"
                >
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    {{ $t("vigik.custom.activityName") }}
                                </th>
                                <th class="mobile">
                                    {{ $t("vigik.custom.provider") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(activity, index) in activities"
                                :key="`activity-table-${index}`"
                            >
                                <td>
                                    {{ activity.label }}
                                </td>
                                <td class="mobile"></td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination
                        :pagesCount="pagesCount"
                        :page="currentPage"
                        v-on:new-current-page="(e) => (this.currentPage = e)"
                    >
                    </pagination>
                </div>
            </div>
        </div>
        <div class="bottom-block" v-if="!loading">
            <basic-button
                color="orange"
                @click="saveVerif"
                :disabled="
                    !name ||
                    (openingPriority !== '0' && openingPriority !== '1')
                "
            >
                <span>
                    {{ $t("button.validate") }}
                </span>
            </basic-button>
            <basic-button color="blue" @click="cancelVerif">
                <span>
                    {{ $t("button.cancel") }}
                </span>
            </basic-button>
        </div>
        <div class="loading" v-if="loading">
            <loader></loader>
        </div>
    </div>
</template>

<script>
import BasicInput from "@/components/basic/BasicInput"
import BasicButton from "@/components/basic/BasicButton.vue"
import BasicSelect from "@/components/basic/BasicSelect.vue"
import Loader from "@/components/basic/Loader.vue"
import VigikBreadCrumb from "@/components/entities/VigikBreadCrumb.vue"
import {
    cloneAccessType,
    createAccessType,
    getAccessType,
    getAccessTypeActivities,
} from "@/services/intratone/accesstype"
import Pagination from "@/components/basic/Pagination"
import { useForm } from "vee-validate"
import { useToast } from "vue-toastification"

const toast = useToast()

export default {
    name: "CreateProfil",
    components: {
        BasicSelect,
        BasicInput,
        Pagination,
        BasicButton,
        Loader,
        VigikBreadCrumb,
    },
    setup() {
        const { validate, errors } = useForm()

        return {
            validate,
            errors,
        }
    },
    data() {
        return {
            name: "",
            description: "",
            copy: "",
            copyCode: "",
            accessTypeList: [],
            openingPriority: null,
            openingPriorityOptions: [
                {
                    name: "-",
                    value: "2",
                },
                {
                    name: this.$t("vigik.form.closed"),
                    value: "0",
                },
                {
                    name: this.$t("vigik.form.opened"),
                    value: "1",
                },
            ],
            pagesCount: 0,
            currentPage: 1,
            activities: [],
            copyLimit: 20,
            copyPage: 1,
            copyCountPage: 1,
            loading: false,
        }
    },
    mounted() {
        this.addPathToBreadcrumb()
        this.getCopy()
    },
    methods: {
        addPathToBreadcrumb() {
            this.$store.commit("breadCrumb/pushBreadCrumb", [
                {
                    name: this.$t("vigik.config.createAccessType"),
                    link: this.$route.path,
                    routeName: this.$route.name,
                },
            ])
        },
        getCopy(value = "", page = 1) {
            this.accessTypeList = [
                {
                    name: "-",
                    value: "0",
                },
            ]
            getAccessType({
                copy: value.data,
                stats: "acces",
                page: page,
                limit: this.copyLimit,
            }).then((response) => {
                response.data.list.forEach((e) => {
                    this.accessTypeList.push({
                        value: e.id,
                        name: e.name,
                    })
                })
                this.copyCountPage = response.data._pages
                this.copyPage = response.data._page
                this.copyLimit = response.data._limit
            })
        },
        goToVigik() {
            this.$router.push({
                name: "Vigik3",
            })
        },
        async createAccessType() {
            this.loading = true
            const validationResponse = await this.validate()

            if (validationResponse.valid) {
                if (!this.copyCode) {
                    return await createAccessType({
                        name: this.name,
                        description: this.description,
                        access_mode_priority: this.openingPriority,
                    }).then((response) => {
                        if (!response.error) {
                            this.$router.push({
                                name: "Vigik3",
                            })
                            this.loading = false
                            return true
                        }
                        this.loading = false
                    })
                } else {
                    return await createAccessType({
                        name: this.name,
                        description: this.description,
                        access_type_id: this.copyCode,
                        access_mode_priority: this.openingPriority,
                    }).then((response) => {
                        if (!response.error) {
                            this.$router.push({
                                name: "Vigik3",
                            })
                            this.loading = false
                            return true
                        }
                        this.loading = false
                    })
                }
            }
        },
        isNotEmpty(value) {
            if (value === "" || value === null || value === undefined) {
                return this.$t("input.empty")
            }
            return true
        },
        isSelected(value) {
            if (!value || (value !== "0" && value !== "1")) {
                return this.$t("input.empty")
            }
            return true
        },
        cancelVerif() {
            this.goToVigik()
        },
        async saveVerif() {
            await this.createAccessType().then((response) => {
                if (!response.error) {
                    toast.success(
                        this.$t("vigik.access.create-access-type", {
                            name: this.name,
                        }),
                        {
                            icon: "fas fa-check",
                        }
                    )
                }
            })
        },
        updateCopy(payload) {
            this.loading = true
            if (payload !== "0" || !payload) {
                this.copy = payload
                this.copyCode = payload
                getAccessTypeActivities(this.copyCode, {
                    page: this.currentPage,
                }).then((response) => {
                    this.activities = response.data.list
                    this.pagesCount = response.data._pages
                    this.loading = false
                })
            } else {
                this.copy = "0"
                this.copyCode = ""
                this.loading = false
            }
        },
        getMore() {
            this.getCopy(this.copy, parseInt(this.copyPage) + 1)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.create-profil-vigik {
    width: 100vw;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media all and (max-width: 768px) {
        padding: 0 20px;
    }
    @media all and (max-width: 480px) {
        padding: 0 20px;
    }
    @media all and (min-width: 768px) {
        padding: 2em 3em 0 3em;
    }
    .return_page {
        @media all and (max-width: 768px) {
            padding-top: 20px;
        }
        a {
            color: $blue-darker;
            cursor: pointer;
            text-decoration: none;
            transition: color 200ms;
            font-family: "Avenir Heavy";
            font-size: 12px;
            i {
                margin-right: 10px;
                transition: margin 200ms;
            }
        }
        a:hover {
            color: $orange-neutral;
            i {
                margin-right: 15px;
            }
        }
    }
    .block-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3em;
        padding-bottom: 1.5em;

        .top-block {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 1em;
            width: 100%;

            h1 {
                margin: 0;
            }

            .title {
                font-family: $font_avenir_black;
                width: 100%;
                @media all and (min-width: 768px) {
                    font-size: $veryBig;
                }
                @media all and (max-width: 768px) {
                    font-size: $veryBig;
                }
            }
        }
        .middle-block {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            .access-form {
                width: 100%;
                max-width: 800px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .input-field {
                    display: flex;
                    justify-content: space-between;
                    gap: 2em;
                    width: 100%;

                    @media all and (max-width: 768px) {
                        flex-direction: column;
                        gap: 1em;

                        .access-type-name,
                        .access-type-description,
                        .select-access-type {
                            width: 100%;
                        }
                    }

                    .input-name {
                        width: 70% !important;
                        position: relative;

                        @media all and (max-width: 480px) {
                            width: 100% !important;
                        }

                        .value-number {
                            font-size: $small;
                            position: absolute;
                            top: 1.2em;
                            right: 1em;
                        }
                    }

                    .description-wrapper {
                        height: 150px;
                        width: 70%;
                        position: relative;

                        @media all and (max-width: 480px) {
                            width: 100% !important;
                        }

                        .access-type-description {
                            height: 100%;
                        }

                        .value-number {
                            position: absolute;
                            bottom: 0.5em;
                            right: 1em;

                            p {
                                margin: 0;
                                font-size: $small;
                            }
                        }
                    }

                    p {
                        min-width: max-content;
                        max-width: max-content;
                        margin: 0;
                    }

                    .input-name,
                    .description-wrapper,
                    .select-access-type {
                        width: 70%;

                        @media all and (max-width: 480px) {
                            width: 100% !important;
                        }
                    }
                }
            }
            .list-display {
                @media all and (max-width: 768px) {
                    width: 100%;
                }
                @media all and (min-width: 768px) {
                    width: 60%;
                }
                margin: 0;
                table {
                    width: 100%;
                    thead {
                        border: none;
                        color: $grey-darker;
                        tr {
                            border-bottom: solid 1px $grey-neutral;
                            th {
                                font-size: $normal;
                                &.number {
                                    text-align: center;
                                }
                                &.check {
                                    text-align: right;
                                }
                            }
                        }
                    }
                    tbody {
                        color: $blue-dark;
                        font-size: $small;
                        font-family: "Avenir Heavy";
                        cursor: pointer;
                        tr {
                            &.add {
                                font-size: $normal;
                                color: $orange-neutral;
                                cursor: pointer;
                            }
                            background-color: $white;
                            transition: background-color 200ms;
                            height: 40px;
                            border-bottom: solid 1px $grey-neutral;
                            td {
                                vertical-align: middle;
                                padding: 2px;
                                i {
                                    font-size: $normal;
                                    cursor: pointer;
                                }
                                svg {
                                    font-size: $normal;
                                    cursor: pointer;
                                }
                                &.number {
                                    font-size: $normal;
                                    text-align: center;
                                }
                                &.check {
                                    font-size: $normal;
                                    text-align: right;
                                }
                            }
                            &:hover {
                                background-color: $grey-light;
                            }
                        }
                        border: none !important;
                    }
                }
            }
        }
    }
    .bottom-block {
        display: flex;
        justify-content: start;
        gap: 20px;
        margin-bottom: 20px;
    }

    .loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
}
</style>
